<template>
	<section class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-md-11 col-12">
				<h1>{{ fullName }}</h1>
			</div>
		</div>
		<NavigationSlide
			:nav="[
				{
					name: 'Info',
					text: 'Info',
					icon: 'info',
				},
				{
					name: 'Account',
					text: 'Roller',
					icon: 'address-card',
					hidden: !$can('update', 'User', 'Role'),
				},
				{
					name: 'Agreements',
					text: 'Aftaler',
					icon: 'handshake',
					hidden: !$can('read', 'User', 'Agreements', this.userId),
				},
				{
					name: 'InactiveAgreements',
					text: 'Inaktive aftaler',
					icon: 'handshake',
					hidden: !$can('read', 'User', 'InactiveAgreements'),
				},
				{
					name: 'Api',
					text: 'API',
					icon: 'code',
					hidden: !$can('read', 'User', 'Api') || !isMe,
				},
				{
					name: 'UserSettings',
					text: $t('userBase.settings'),
					icon: 'cog',
					hidden: !$can('delete', 'User'),
				},
				{
					name: 'UserLogs',
					text: 'Logs',
					icon: 'server',
					hidden: !$can('read', 'User', 'Logs'),
				},
			]"
		/>
		<div class="row" v-if="isReady">
			<div class="col">
				<router-view />
			</div>
		</div>
	</section>
</template>

<script>
import NavigationSlide from '@/modules/global/components/NavigationSlide.vue'
export default {
	name: 'user',
	components: { NavigationSlide },
	async created() {
		await this.$store.dispatch('userVuex/reload', this.userId)
		this.$emit('updateLoader')
	},
	computed: {
		isMe() {
			return this.userId == this.$store.getters['auth/uid']
		},
		userId() {
			return this.$route.params.id
		},
		user() {
			return this.$store.getters['userVuex/user']
		},
		email() {
			return this.user?.email ?? ''
		},
		fullName() {
			var firstName = this.user?.firstName ?? ''
			var lastName = this.user?.lastName ?? ''
			return firstName + ' ' + lastName
		},
		isReady() {
			return this.$store.getters['userVuex/isReady']
		},
		canReadOrIsMe() {
			if (this.isMe) {
				return true
			} else {
				return this.$can('read', 'User', 'Account')
			}
		},
	},
	destroyed() {
		this.$store.dispatch('userVuex/clearState')
	},
}
</script>

<style></style>
