var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-md-11 col-12"},[_c('h1',[_vm._v(_vm._s(_vm.fullName))])])]),_c('NavigationSlide',{attrs:{"nav":[
			{
				name: 'Info',
				text: 'Info',
				icon: 'info',
			},
			{
				name: 'Account',
				text: 'Roller',
				icon: 'address-card',
				hidden: !_vm.$can('update', 'User', 'Role'),
			},
			{
				name: 'Agreements',
				text: 'Aftaler',
				icon: 'handshake',
				hidden: !_vm.$can('read', 'User', 'Agreements', this.userId),
			},
			{
				name: 'InactiveAgreements',
				text: 'Inaktive aftaler',
				icon: 'handshake',
				hidden: !_vm.$can('read', 'User', 'InactiveAgreements'),
			},
			{
				name: 'Api',
				text: 'API',
				icon: 'code',
				hidden: !_vm.$can('read', 'User', 'Api') || !_vm.isMe,
			},
			{
				name: 'UserSettings',
				text: _vm.$t('userBase.settings'),
				icon: 'cog',
				hidden: !_vm.$can('delete', 'User'),
			},
			{
				name: 'UserLogs',
				text: 'Logs',
				icon: 'server',
				hidden: !_vm.$can('read', 'User', 'Logs'),
			} ]}}),(_vm.isReady)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-view')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }